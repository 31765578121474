import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import BookPage from "./pages/BookPage";
import Home from "./pages/Home";
import StatusPage from "./pages/StatusPage";

function App() {
  return (
    <Router>
      <div style={styles.container}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/books/:title/:id" element={<BookPage />} />
          <Route path="/status" element={<StatusPage />} />
        </Routes>
      </div>
    </Router>
  );
}

const styles = {
  container: {
    padding: "20px 15px",
  },
};

export default App;
