// src/components/BookList.js
import { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { getBookList } from "../api";
import { BookListItem } from "../api/types";
import "./BookList.css";

const PAGE_SIZE = 40;

const BookList = () => {
  const [books, setBooks] = useState<BookListItem[]>([]);
  const [nextCursor, setNextCursor] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const hasFetched = useRef(false);
  const [hasMoreBooks, setHasMoreBooks] = useState(true);

  const fetchBooks = useCallback(async () => {
    if (loading || !hasMoreBooks) return;
    setLoading(true);
    try {
      const { books: newBooks, next_cursor } = await getBookList(
        nextCursor,
        PAGE_SIZE
      );
      setBooks((prevBooks) => [...prevBooks, ...newBooks]);

      if (newBooks.length < PAGE_SIZE) {
        setHasMoreBooks(false);
        setNextCursor(null);
      } else {
        setNextCursor(next_cursor || null);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [nextCursor, loading, hasMoreBooks]);

  useEffect(() => {
    if (hasFetched.current) return;
    hasFetched.current = true;
    fetchBooks();
  }, [fetchBooks]);

  const renderSkeletons = () => {
    return Array(4)
      .fill(0)
      .map((_, index) => (
        <div key={`skeleton-${index}`} className="book-card skeleton">
          <div className="skeleton-image" />
          <div className="skeleton-title" />
          <div className="skeleton-author" />
        </div>
      ));
  };

  return (
    <div>
      <h2>Books</h2>
      {books.length === 0 && !loading && <p>No books available.</p>}
      <div className="book-grid">
        {books.map((book, index) => (
          <Link
            to={`/books/${encodeURIComponent(book.title.toLowerCase().replace(/[^a-z0-9]+/g, '-'))}/${book.id}`}
            key={index}
            className="book-link"
            title={`${book.title} by ${book.authors}`}
          >
            <div className="book-card">
              <img src={book.image_l} alt={book.title} className="book-image" />
              <div className="book-info">
                <h3 className="book-title">{book.title}</h3>
                <p className="book-author">by {book.authors}</p>
              </div>
            </div>
          </Link>
        ))}
        {loading && renderSkeletons()}
      </div>
      <button
        onClick={fetchBooks}
        className="load-more-button"
        disabled={loading || !hasMoreBooks}
      >
        {loading
          ? "Loading..."
          : hasMoreBooks
          ? "Load More Books"
          : "No More Books"}
      </button>
    </div>
  );
};

export default BookList;
