import { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import "./StatusPage.css";

function StatusPage() {
  const [status, setStatus] = useState("Connecting...");
  const [statusClass, setStatusClass] = useState("");
  const [messages, setMessages] = useState<string[]>([]);
  const logContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Update document head
    document.title = "WebSocket Test";

    // Setup WebSocket connection
    const wsUrl =
      window.location.hostname === "localhost"
        ? "ws://localhost:5050"
        : //   : 'ws://m2max:5050';
          "wss://api.summz.ai";

    const socket = io(wsUrl, {
      transports: ["websocket"],
      upgrade: false,
    });

    socket.on("connect", () => {
      setStatus("Connected!");
      setStatusClass("connected");
      console.log("Connected to WebSocket server");
    });

    socket.on("connect_error", (error) => {
      setStatus(`Connection error: ${error}`);
      setStatusClass("error");
      console.error("Connection error:", error);
    });

    socket.on("log_message", (data) => {
      setMessages((prevMessages) => {
        const newMessages = [...prevMessages, data.message].slice(-1000); // Keep last 1000 messages
        return newMessages;
      });
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  // Auto-scroll effect
  useEffect(() => {
    if (logContainerRef.current) {
      logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="statusPage">
      <div className="pageContainer">
        <h1 className="heading">Summz's Secret Pathway</h1>
        <div id="status" className={`${status} ${statusClass}`}>
          {status}
        </div>
        <h2 className="heading">Log Messages</h2>
        <div id="log-container" className="logContainer" ref={logContainerRef}>
          {messages.map((message, index) => (
            <div key={index} className="logEntry">
              {message}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default StatusPage;
