import axios from "axios";
import { Book, BookListResponse } from "./types";

const API_BASE_URL = "https://api.summz.ai";

export const fetchBookSummary = async (bookId: string): Promise<Book> => {
  const response = await axios.get(`${API_BASE_URL}/book_summary/${bookId}`);
  return response.data;
};

export const getBookList = async (
  cursor: string | null,
  pageSize: number
): Promise<BookListResponse> => {
  const response = await axios.get(`${API_BASE_URL}/books`, {
    params: {
      cursor: cursor,
      page_size: pageSize,
    },
  });

  return response.data;
};
