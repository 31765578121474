import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fetchBookSummary } from "../api";
import { Book } from "../api/types";
import "./BookDetail.css";

const BookDetail = () => {
  const { id } = useParams();
  const [book, setBook] = useState<Book | null>(null);
  const [activeTab, setActiveTab] = useState(0);
  const [isMixingMode, setIsMixingMode] = useState(false);
  const [secondaryTab, setSecondaryTab] = useState(1);
  const [error, setError] = useState<string>("");
  const [isTocOpen, setIsTocOpen] = useState(false);

  useEffect(() => {
    fetchBookSummary(id!)
      .then((bookData) => {
        setBook(bookData);
      })
      .catch((err) => {
        console.error(err);
        setError("Failed to load book details. Please try again later.");
      });
  }, [id]);

  if (error) return <p>{error}</p>;
  if (!book) return <p>Loading...</p>;

  // Function to get the appropriate icon class
  const getIconClass = (type: string) => {
    switch (type.toLowerCase()) {
      case "pdf":
        return "fa-solid fa-file-pdf";
      case "epub":
        return "fa-solid fa-book"; // Using a book icon for EPUB as there's no specific EPUB icon
      default:
        return "fa-solid fa-file"; // Default file icon
    }
  };

  // Function to scroll to a specific summary
  const scrollToSummary = (index: number) => {
    const element = document.getElementById(`summary-${index}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="book-detail-container">
      <nav className="breadcrumb">
        <Link to="/">Books</Link> &gt; {book.title}
      </nav>

      <header className="book-header">
        <div className="book-info">
          <h1>{book.title}</h1>
          <div className="subtitle">{book.subtitle}</div>
          <div className="author">by {book.authors}</div>
        </div>
        <img src={book.image_m} alt={book.title} className="book-cover" />
      </header>

      <nav className="main-tabs">
        {book.summaries.map((summary, index) => (
          <button
            key={index}
            onClick={() => {
              setIsMixingMode(false);
              setActiveTab(index);
            }}
            className={activeTab === index && !isMixingMode ? "active" : ""}
          >
            {summary.language}
          </button>
        ))}
        <button
          onClick={() => {
            setIsMixingMode(true);
            setActiveTab(0);
            setSecondaryTab(1);
          }}
          className={isMixingMode ? "active" : ""}
        >
          Mix Languages
        </button>
      </nav>

      {isMixingMode ? (
        <article className="mixed-language-content">
          <div className="download-section">
            {book.summaries[activeTab].artifacts.map(
              (artifact, index) =>
                artifact.file_name && (
                  <a
                    key={index}
                    href={artifact.file_name}
                    download
                    className="download-link"
                  >
                    <i className={getIconClass(artifact.type)} />
                    Summary {artifact.type.toUpperCase()}
                  </a>
                )
            )}
          </div>
          <nav className="table-of-contents">
            <div
              className="toc-header"
              onClick={() => setIsTocOpen(!isTocOpen)}
            >
              <h2>Table of Contents</h2>
              <i
                className={`fa-solid ${
                  isTocOpen ? "fa-chevron-up" : "fa-chevron-down"
                }`}
              ></i>
            </div>
            <ul className={isTocOpen ? "open" : ""}>
              {book.summaries[activeTab].summary_list.map((summary, index) => (
                <li key={index}>
                  <a
                    href={`#summary-${index}`}
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSummary(index);
                    }}
                  >
                    {summary.title}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
          {book.summaries[activeTab].summary_list.map((summary, index) => (
            <section key={index} id={`summary-${index}`}>
              <h2>
                {summary.title} /{" "}
                {book.summaries[secondaryTab].summary_list[index]?.title}
              </h2>
              {summary.text.split("\n").map((line, i) => {
                const secondaryText =
                  book.summaries[secondaryTab].summary_list[index]?.text.split(
                    "\n"
                  )[i];
                return (
                  <React.Fragment key={i}>
                    <p>{line}</p>
                    {secondaryText && secondaryText.trim() && (
                      <p className="secondary-language">{secondaryText}</p>
                    )}
                  </React.Fragment>
                );
              })}
            </section>
          ))}
        </article>
      ) : (
        <article className="single-language-content">
          <div className="download-section">
            {book.summaries[activeTab].artifacts.map(
              (artifact, index) =>
                artifact.file_name && (
                  <a
                    key={index}
                    href={artifact.file_name}
                    download
                    className="download-link"
                  >
                    <i className={getIconClass(artifact.type)} />
                    Summary {artifact.type.toUpperCase()}
                  </a>
                )
            )}
          </div>
          <nav className="table-of-contents">
            <div
              className="toc-header"
              onClick={() => setIsTocOpen(!isTocOpen)}
            >
              <h2>Table of Contents</h2>
              <i
                className={`fa-solid ${
                  isTocOpen ? "fa-chevron-up" : "fa-chevron-down"
                }`}
              ></i>
            </div>
            <ul className={isTocOpen ? "open" : ""}>
              {book.summaries[activeTab].summary_list.map((summary, index) => (
                <li key={index}>
                  <a
                    href={`#summary-${index}`}
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSummary(index);
                    }}
                  >
                    {summary.title}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
          {book.summaries[activeTab].summary_list.map((summary, index) => (
            <section key={index} id={`summary-${index}`}>
              <h2>{summary.title}</h2>
              {summary.text.split("\n").map((line, i) => (
                <p key={i}>{line}</p>
              ))}
            </section>
          ))}
        </article>
      )}
    </div>
  );
};

export default BookDetail;
